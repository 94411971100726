import React from "react"

import main from "../images/main.gif"
import "../global.css"

const IndexPage = () => (
  <main>
    <img src={main} className="bg-video" />
    <div className="overlay">
      <h1>
        MICRODOSING<br></br> HAPPINESS
      </h1>
    </div>
  </main>
)

export default IndexPage
